/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        AOS.init();

        $( ".js-mobile-trigger" ).click(function() {
          $( 'body' ).toggleClass( "is-mobile-menu" );
        });


        ////

        $('.masterhead-home-slide').owlCarousel({
          items : 1,
          slideSpeed : 6000,
          nav: true,
          autoplay: true,
          animateOut: 'fadeOut',
          dots: true,
          loop: true,
          margin: 10,
          // responsiveRefreshRate : 200,
        });

        ////

        ///// Initalize Paroller.js

        if ($(window).width() > 767) {
          $('.has-parallax').paroller();
        }

        ////

        function checkIfError() {
          
          if ($(".validation_error").length) {
            
            $(".custom-error-message").remove();

            $("<div class='custom-error-message'>\
                Oops! Please complete the information above.\
              </div>").insertAfter(".gform_footer");

          }

        }

        function getUrlVars() {
            var vars = [], hash;
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for(var i = 0; i < hashes.length; i++)
            {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
            return vars;
        }

        function checkIfSuccess() {
          if( getUrlVars()["success"] && $(".form-default").length ) {
            $("#formModal").modal("show");
          }
          if( getUrlVars()["success_kidsclub"] && $(".form-default").length ) {
            $("#formModalKids").modal("show");
          }
        }

        function scrollToPage(selector) {
          if( $(selector).length ) {
            $("html, body").animate({
              "scrollTop": $(selector).offset().top - 50
            }, 500);
          }
        }

        // check if error
        checkIfError();
        checkIfSuccess();


        // gform callback
        $(document).bind('gform_post_render', function(){
          // when successful
          // successCallback();

          // when has error
          checkIfError();
        });


        ////

        var $window = $(window),
            $menuWrapper = $(".mobile-menu-wrapper");

        $window.on("scroll", function() {
          
          if($(this).scrollTop() > 50) {
            $menuWrapper.addClass("sticky");
          } else {
            $menuWrapper.removeClass("sticky");
          }

        });

        var $dropdown = $(".blog-category-dropdown select"),
            val = "",
            isBlog = false;
        $dropdown.append("<option value='all'>All</option>");

        if(window.location.pathname.indexOf("/blog") != -1) {
          $dropdown.val("all");
          isBlog = true;
        }

        $dropdown.on("change", function() {
          val = $(this).val();

          if(isBlog) {
            if(val == "all") {
              window.location.reload();
            } else {
              window.location.href = "../category/" + val;
            }
          } else {
            if(val == "all") {
              window.location.href = "../../blog";
            } else {
              window.location.href = "../" + val;
            }
          }
          
          
        });

        setTimeout(function() {
          var hash = window.location.hash;
          scrollToPage(hash);
        }, 1000);

        $(".link-ico").on("click", function(e) {
          e.preventDefault();
          
          scrollToPage($(this).attr("href"));
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        setTimeout(function() {
          $('.onload-popop').show();

          $('.onload-popop').attr('data-aos', 'fade-up');


        }, 5000);

        $( ".onload-close" ).click(function() {
          $('.onload-popop').addClass('is-hide');
        });


        var $homeDropdown = $(".home-dropdown");

        $("#btnGo").on("click", function() {
          window.location.href = "/stores-map?filter=" + $homeDropdown.val();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'getting_here': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'kids_club': {
      init: function() {

        window.childGroupNum = 1;

        function hideChildGroup() {

          $("[class*='child_']")
            .addClass("hide")
            .hide();

        }

        function removeBtnAddChildGroup() {

          $("#btnAddChildGroup").remove();

        }

        function showChildGroup(num) {

          var $group = $(".child_" + window.childGroupNum++);
          if($group.length) {
            $group
              .show()
              .removeClass("hide");
          }

          if (window.childGroupNum == 6) removeBtnAddChildGroup();

        }

        function removeEmptyLabel() {

          $("label:empty").remove();

        }

        function customEventListeners() {

          $(document).on("click", "#btnAddChildGroup", function() {
            showChildGroup();
            $(".two-col").removeAttr("style");
          });


          $(document).bind('gform_post_render', function(){
            var numShown = window.childGroupNum,
                selector = [];

            hideChildGroup();

            for(var i = 1; i < numShown; i++) {
              selector.push("[class*='child_" + i + "']");
            }

            if(numShown == 6) {
              removeBtnAddChildGroup();
            }

            $(selector.join(", "))
              .show()
              .removeClass("hide");

          });
        }

        function customInit() {
          // remove attr style
          setTimeout(function() {
            $(".two-col").removeAttr("style");
          }, 500);

          // hiding all child group of kids club sign up form
          hideChildGroup();

          // show first child group
          showChildGroup();

          // remove all empty labels
          removeEmptyLabel();

          // initiate event listeners
          customEventListeners();

        }

        customInit();
      }
    },
    'stores_map': {
      init: function() {
        function getUrlVars() {
            var vars = [], hash;
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for(var i = 0; i < hashes.length; i++)
            {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
            return vars;
        }

        var GrayScaleFix = (function() {
          var needToFix = /(MSIE 10)|(Trident.*rv:11\.0)|( Edge\/[\d\.]+$)/.test(navigator.userAgent);

          function replaceImage(image) {
            var tmpImage = new Image();
            tmpImage.onload = function() {
              var imgWrapper = document.createElement('span'),
                svgTemplate = 
                '<svg xmlns="http://www.w3.org/2000/svg" id="svgroot" viewBox="0 0 '+tmpImage.width+' '+tmpImage.height+'" width="100%" height="100%">' +
                  '<defs>' +
                  '<filter id="gray">' +
                    '<feColorMatrix type="matrix" values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0" />' +
                  '</filter>' +
                  '</defs>' +
                  '<image filter="url(&quot;#gray&quot;)" x="0" y="0" width="'+tmpImage.width+'" height="'+tmpImage.height+'" preserveAspectRatio="none" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="'+tmpImage.src+'" />' +
                '</svg>';
              
              imgWrapper.innerHTML = svgTemplate;
              imgWrapper.className = 'grayscale-fix';
              image.parentNode.insertBefore(imgWrapper, image);

              image.style.cssText += 'visibility:hidden;display:block';
              imgWrapper.querySelector('svg').style.position = 'absolute';
              imgWrapper.style.cssText = 'display:inline-block;position:relative;';
              imgWrapper.appendChild(image);
            };
            tmpImage.src = image.src;
          }

          function replaceAll() {
            var images = document.querySelectorAll('img.grayscale');
            for(var i = 0; i < images.length; i++) {
              replaceImage(images[i]);
            }
          }

          if(needToFix) {
            document.addEventListener('DOMContentLoaded', replaceAll);
          }

          return {
            replace: replaceImage,
            refresh: replaceAll
          };
        }());

        setTimeout(function() {
          var filter = "." + getUrlVars()["filter"];
          $("[data-filter='" + filter + "']").trigger("click");
        }, 500);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  /**
   * L&B Extension of theme
   */
  $('.notification__close').click(function() {
    /**
     * Hide the notification on click
     */
    $('.notification').hide();

    /**
     * Set a disabled cookie for 1 week
     */
    Cookies.set('notificationDisabled', true, { expires: 7 });
  })

  /**
   * Check if the notification has been disabled
   */
  if ( ! Cookies.get('notificationDisabled') ) {
    $('.notification').removeClass('hidden');
  }

})(jQuery); // Fully reference jQuery after this point.
